<template>
    <div id="newDashboardPage" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="newDashboard"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="analytics_createNewDashboardSubTitle"></app-sitetitle>
                            <!-- <div class="kt-subheader__toolbar">
                                <div class="kt-subheader__wrapper">
                                    <a href="#" class="btn btn-label-warning btn-bold btn-sm btn-icon-h kt-margin-l-10">
                                        BUTTONS IF NEEDED
                                    </a>
                                </div>
                            </div> -->
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="kt-portlet">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z" fill="#000000" fill-rule="nonzero" />
                                                            <path d="M8.7295372,14.6839411 C8.35180695,15.0868534 7.71897114,15.1072675 7.31605887,14.7295372 C6.9131466,14.3518069 6.89273254,13.7189711 7.2704628,13.3160589 L11.0204628,9.31605887 C11.3857725,8.92639521 11.9928179,8.89260288 12.3991193,9.23931335 L15.358855,11.7649545 L19.2151172,6.88035571 C19.5573373,6.44687693 20.1861655,6.37289714 20.6196443,6.71511723 C21.0531231,7.05733733 21.1271029,7.68616551 20.7848828,8.11964429 L16.2848828,13.8196443 C15.9333973,14.2648593 15.2823707,14.3288915 14.8508807,13.9606866 L11.8268294,11.3801628 L8.7295372,14.6839411 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("analytics_createNewDashboard") }}
                                                </h3>
                                            </div>
                                        </div>

                                        <!--begin::Form-->
                                        <form class="kt-form" v-on:submit.prevent="onCreateButton" novalidate="novalidate">
                                            <div class="kt-portlet__body">
                                                <div class="form-group validated">
                                                    <label for="newDashboardForm_nameInput">{{ $t("analytics_dashboardName") }} *</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i class="la la-pencil"></i></span>
                                                        </div>
                                                        <input v-model="name" @input="$v.name.$touch()" type="text" class="form-control" id="newDashboardForm_nameInput" :placeholder="$t('analytics_dashboardNameInputInfo')" />
                                                    </div>
                                                    <div v-if="!$v.name.required" class="invalid-feedback">
                                                        {{ $t("error_fieldIsRequired") }}
                                                    </div>
                                                    <div v-else-if="!$v.name.minLen" class="invalid-feedback">
                                                        {{ $t("error_minLengthMsg", [$v.name.$params.minLen.min]) }}
                                                    </div>
                                                    <span class="form-text text-muted">{{ $t("analytics_dashboardNameDetailInfo") }}</span>
                                                </div>
                                                <div class="form-group form-group-last validated">
                                                    <label for="newDashboardForm_refreshIntervalInput">{{ $t("analytics_dashboardRefreshInterval") }}</label>
                                                    <select class="form-control" id="editDashboardForm_selectRefreshInterval" v-model="refreshInterval" @change="onChangeForm">
                                                        <option value="10"> {{ $tc("analytics_RI_seconds", 10) }}</option>
                                                        <option value="30">{{ $tc("analytics_RI_seconds", 30) }}</option>
                                                        <option value="60">{{ $tc("analytics_RI_minutes") }} </option>
                                                        <option value="180">{{ $tc("analytics_RI_minutes", 3) }} </option>
                                                        <option value="600">{{ $tc("analytics_RI_minutes", 10) }} </option>
                                                        <option value="3600">{{ $tc("analytics_RI_hours", 1) }} </option>
                                                    </select>
                                                    <div v-if="invalidRI" class="invalid-feedback">
                                                        {{ $t("analytics_dashboardRIError") }}
                                                    </div>

                                                    <span class="form-text text-muted">{{ $t("analytics_dashboardRIDetailInfo") }}</span>
                                                </div>
                                            </div>
                                            <div class="kt-portlet__foot">
                                                <div class="kt-form__actions kt-form__actions--right">
                                                    <label class="kt-checkbox kt-margin-r-20">
                                                        <input v-model="createAnother" type="checkbox" /> {{ $t("common_createAnother") }}
                                                        <span></span>
                                                    </label>
                                                    <button id="newDashboardCreateButton" @click="onCreateButton" type="button" class="btn btn-brand kt-margin-r-5" :disabled="$v.$invalid || invalidRI">{{ $t("common_add") }}</button>
                                                    <button id="newDashboardCancelButton" @click="onCancelButton" type="button" class="btn btn-secondary">{{ $t("common_cancel") }}</button>
                                                </div>
                                            </div>
                                        </form>
                                        <!--end::Form-->
                                    </div>
                                </div>
                            </div>

                            <!-- end:: Content -->
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                    <!-- begin:: Modal to confirm leave page -->
                    <app-confirmleavepagemodal ref="confirmleavepagemodalref"></app-confirmleavepagemodal>
                    <!-- end::Modal -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import SiteTitle from "./../site/widgets/sitetitle.vue";
import commonVueHelper from "../../helpers/commonVueHelper";

export default {
    data() {
        return {
            name: "",
            refreshInterval: 30,
            siteId: this.$route.params.siteId,
            createAnother: false,
            invalidRI: false
        };
    },
    created: function() {
        console.log("Component(newDashboard)::created() - called");
        this.createAnother = this.createAnotherDashboard;
        this.setCreateAnotherDashboard(false);
    },
    mounted: function() {
        console.log(
            "Component(newDashboard)::mounted() - Init metronic layout"
        );
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(newDashboard)::destroyed() - called");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        name: {
            required,
            minLen: minLength(2)
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["createAnotherDashboard"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["createDashboard", "setCreateAnotherDashboard"]),
        //Function called to check provided refresh interval
        checkRefreshInterval() {
            if (this.refreshInterval >= 10) {
                this.invalidRI = false;
            } else {
                this.invalidRI = true;
            }
        },
        // Function called when user click on the "Cancel" button
        onCancelButton() {
            console.log("Component(newDashboard)::onCancelButton() - called");
            this.$router.push({
                name: "dashboards",
                params: { fromAction: "cancelButton" }
            });
        },

        onCreateButton() {
            console.log("Component(newDashboard)::onCreateButton() - called");
            const data = {
                siteId: this.siteId,
                name: this.name,
                refreshInterval: this.refreshInterval,
                createAnother: this.createAnother
            };
            this.createDashboard(data);
        },

        isEmptyForm() {
            // Return true if all control form are empty
            let isEmptyForm = true;
            if (this.name || this.refreshInterval !== 30) {
                isEmptyForm = false;
            }
            return isEmptyForm;
        },

        onChangeForm() {
            this.invalidRI = ![10, 30, 60, 180, 600, 3600].includes(
                parseInt(this.refreshInterval)
            );
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-sitetitle": SiteTitle
    },

    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        // --
        commonVueHelper.displayConfirmModalOnLeavePage(
            this,
            "NewDashboard",
            "create",
            !this.isEmptyForm(),
            to,
            from,
            next
        );
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
